import { useEffect, useMemo, useRef } from 'react';
import { Input } from '@mui/material';
import { useEditorActions } from 'features/resolutionPanel/middlePanel/auditLog/hooks';
import useTexts from 'features/resolutionPanel/useTexts';
import { StyledSubjectInput } from './style';

const SubjectInput = ({ title, srId }) => {
  const { handleEditorChange } = useEditorActions();
  const { subjectFormat } = useTexts();
  const subjectFormatText = useMemo(() => subjectFormat(title, srId), [subjectFormat, title, srId]);
  const indexOfTitle = subjectFormatText.indexOf(title);
  const srIdPrefix = subjectFormatText.slice(0, indexOfTitle);
  const initializedRef = useRef(false);

  const onChange = (e) => {
    handleEditorChange('subject', { subject: e.target.value });
    handleEditorChange('completeSubject', { completeSubject: `${srIdPrefix}${e.target.value}` });
  };

  useEffect(() => {
    if (title && srId && !initializedRef.current) {
      initializedRef.current = true;
      handleEditorChange('subject', { subject: title });
      handleEditorChange('completeSubject', { completeSubject: `${srIdPrefix}${title}` });
    }
  }, [handleEditorChange, srIdPrefix, title, srId]);

  return (
    <StyledSubjectInput>
      {srId && (
        <span className="sr-id" data-testid="messages-subject-prefix">
          {srIdPrefix}
        </span>
      )}
      <Input
        disableUnderline
        type="text"
        name="subject"
        value={title}
        onChange={onChange}
        data-testid="messages-subject-input"
      />
    </StyledSubjectInput>
  );
};

export default SubjectInput;
